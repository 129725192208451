.not-found {
  width:100%;
  height:100vh;
  overflow: hidden;
  .gif-container {
    object-fit: contain;
    width:100%;
    height:100vh;
  }
}
