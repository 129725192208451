.gif-tv {
  width:100%;
  height:100vh;
  overflow: hidden;
  .gif {
    object-fit: contain;
    width:100%;
    height:100vh;
  }
}
